import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'

import Image from '../components/image'
import Link from '../components/link'
import PageLinks from '../components/page-links'
import forwardArrow from '../images/forward_arrow.png'
import { classes } from '../util/components'
import styles from './work-with-us.module.css'
import SEO from '../components/seo'

const WorkWithUsSections = ({ sections }) =>
  sections.map((s, i) => (
    <div key={i} className={styles.sectionsWrapper}>
      <div className="flex flex-col md:flex-row items-center text-left">
        <div className={`flex-1 ${styles.detailsWrapper}`}>
          <h2 className={`text-white ${styles.detailsTitle}`}>{s.title}</h2>
          <div
            className={`text-white ${styles.detailsBlurb}`}
            dangerouslySetInnerHTML={{ __html: s.blurbHtml }}
          ></div>
          {s.callToAction ? (
            <Link className="btn btn-primary inline-block" to={s.linkURL}>
              {s.callToAction}
            </Link>
          ) : null}
        </div>
        <div className={`flex-1 mt-10 md:mt-0 ${styles.coverWrapper}`}>
          <div className={styles.coverImage}>
            {s.image && s.image.childImageSharp ? (
              <Image
                fluid={s.image.childImageSharp.fluid}
                alt={s.title}
              ></Image>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  ))

export const WorkWithUsTemplate = ({ page }) => {
  const {
    title,
    blurb,
    sections,
    careersPlaceholderHtml,
    pageLinks,
  } = page.frontmatter

  const { jobPostings } = page.fields
  return (
    <>
    <SEO 
      title={"Find a Career with The Projector | Jobs at the Projector"}
      description={"Work with a small team that hustles very hard and everyone plays their part.Enthusiastic and committed volunteers with a good working attitude are also needed."}
    />
    <div className="container flex flex-col items-center text-center py-5 lg:py-20">
      <div>
        <div className={styles.title}>{title}</div>
        <div className={styles.blurb}>{blurb}</div>
      </div>
      {sections && sections.length ? (
        <WorkWithUsSections sections={sections} />
      ) : null}
      <div className={styles.careersWrapper}>
        <div className={styles.careersTitle}>Careers</div>
        {jobPostings && jobPostings.length ? (
          <div className={styles.postings}>
            {jobPostings.map((p, i) => (
              <Link
                className={classes(
                  'w-full no-underline text-left',
                  styles.posting
                )}
                to={p.fields.slug}
                key={i}
                aria-label={`Go to job posting '${p.frontmatter.title}'`}
              >
                <span>{p.frontmatter.title}</span>
                <img src={forwardArrow} alt="forward-arrow" />
              </Link>
            ))}
          </div>
        ) : (
          <div
            className={styles.careersPlaceholder}
            dangerouslySetInnerHTML={{ __html: careersPlaceholderHtml }}
          ></div>
        )}
      </div>
      <div className="w-full mt-30">
        <PageLinks links={pageLinks} />
      </div>
    </div>
    </>
  )
}

const WorkWithUsPage = ({ data }) => {
  return <WorkWithUsTemplate {...data} />
}

WorkWithUsPage.propTypes = {
  data: PropTypes.shape({
    page: PropTypes.object,
  }),
}

export default WorkWithUsPage

export const pageQuery = graphql`
  query WorkWithUsPage {
    page: markdownRemark(fields: { slug: { eq: "/work-with-us/" } }) {
      fields {
        jobPostings {
          fields {
            slug
          }
          frontmatter {
            title
          }
        }
      }
      frontmatter {
        title
        blurb
        sections {
          title
          blurbHtml
          image {
            childImageSharp {
              fluid(maxWidth: 678, maxHeight: 453, quality: 90) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          callToAction
          linkURL
        }
        careersPlaceholderHtml
        ...PageLinks
      }
    }
  }
`
